<template>
  <div>
    <OrderDialog
      v-if="itemDialog.visible"
      :user_states="itemDialog.user_states || []"
      :uuidItem="itemDialog.item.uuid"
      :typeDialog="itemDialog.type"
      settingDialog="crm"
      no-cross
      :isCallCenter="true"
    />
    <div v-else class="cabinet-del-state-list pa-0">
      <div class="d-flex align-center justify-content-center" style="height:inherit">
        <span class="font-weight-medium text-h6">{{ $t("table.noData") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import OrderDialog from "../../orderDialog/OrderDialog.vue";

export default {
  props: {
    boardsItem: Object,
    itemDialog: Object
  },
  data: () => ({}),
  mounted() {
    // console.log(this.boardsItem);
  },
  watch: {
    itemDialog: {
      deeo: true,
      handler(e) {
        console.log(e);
      }
    }
  },
  computed: {},
  components: {
    OrderDialog
  }
};
</script>

<style lang="scss">
.cabinet-del-state-list {
  min-height: 25rem;
  max-height: 25rem;
  height: 25rem;
  overflow-y: scroll;
}
.empty-image {
  position: absolute;
  width: 40%;
  opacity: 0.05;
}
</style>

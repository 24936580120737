<template>
  <v-card class="call-center__wrapper">
    <VCardTitle class="py-2 px-1">
      <v-row justify="space-between">
        <v-col cols="3" class="d-flex">
          <VAutocomplete
            dense
            :items="boardSetting.list"
            solo
            :item-value="'uuid'"
            :placeholder="boardSetting.list.length ? '' : $t('table.noData')"
            :no-data-text="$t('table.noData')"
            :item-text="'name'"
            v-model="boardsItem"
            return-object
            :hide-details="true"
            @change="changeBoard"
            :menu-props="{ bottom: true, offsetY: true }"
          >
            <template v-slot:prepend-inner>
              <v-app-bar-nav-icon
                dense
                @click.stop="treeSideBar = !treeSideBar"
              ></v-app-bar-nav-icon>
            </template>
          </VAutocomplete>
        </v-col>
        <v-col cols="12" sm="6" class="text-right">
          <VBtn
            :block="$vuetify.breakpoint.xs"
            class="success-bg text-white text-transform-none br-10"
            @click="onAddOrder"
            :disabled="!boardsItem.uuid"
          >
            <VIcon left>mdi-plus</VIcon>
            {{ $t("btn.addOrder") }}
          </VBtn>
        </v-col>
      </v-row>
    </VCardTitle>
    <VDivider />
    <v-row no-gutters>
      <v-col :style="$vuetify.breakpoint.smAndDown ? '' : 'width:0px!important'">
        <v-navigation-drawer
          v-model="treeSideBar"
          class="px-1 pt-1 w-100"
          hide-overlay
          style="top: 60px;z-index: 1"
          :absolute="$vuetify.breakpoint.smAndDown"
        >
          <div class="call-center__aside">
            <VAutocomplete
              dense
              outlined
              :items="states"
              :item-value="'uuid'"
              :item-text="'name'"
              v-if="states.length"
              v-model="activeStatus"
              return-object
              :hide-details="true"
              :menu-props="{ bottom: true, offsetY: true }"
            >
              <template slot="item" slot-scope="{ item }">
                <div class="user-state">
                  {{ item?.name }}
                  <span :style="`background-color: ${item.color};`"></span>
                </div>
                <span class="text-caption ml-5" v-if="item?.call_required_amount != '0'">
                  ({{ item?.call_required_amount }})
                </span>
              </template>
              <template slot="selection" slot-scope="{ item }">
                <div class="user-state">
                  {{ item?.name }}
                  <span :style="`background-color: ${item.color};`"></span>
                </div> </template
            ></VAutocomplete>

            <OrderItemsList
              :activeStatus="activeStatus"
              :states="states"
              @set_all="setAll"
              :uuid_board="boardsItem.uuid"
            ></OrderItemsList>
          </div>
        </v-navigation-drawer>
      </v-col>
      <v-col class="call-ce__aside" :cols="treeSideBar && !$vuetify.breakpoint.smAndDown ? 9 : 12">
        <ContentDialog
          :boardsItem="boardsItem"
          :itemDialog="itemDialog"
          @close="itemDialog.visible = false"
        />
      </v-col>
    </v-row>
    <DialogWrapper
      v-if="boardsItem.uuid"
      :visible="createDialog.visible"
      :typeDialog="createDialog.type"
      @close="createDialog.visible = false"
      settingDialog="crm"
    >
      <OrderDialog
        :user_states="createDialog.user_states || []"
        :uuidItem="createDialog.item.uuid"
        :typeDialog="createDialog.type"
        settingDialog="crm"
      ></OrderDialog>
    </DialogWrapper>
  </v-card>
</template>

<script>
import user from "../../mixins/user";
import loader from "../../mixins/loader";
import notifications from "../../mixins/notifications";
import OrderItemsList from "./order-list/orderItemsList.vue";
import crmService from "@/services/request/crm/crmService";
import { mapActions, mapGetters } from "vuex";
import * as actionTypes from "@/store/modules/crm/types/actions";
import * as getterTypes from "@/store/modules/crm/types/getters";
import ContentDialog from "./content/ContentDialog.vue";
import EventBus from "@/events/EventBus";
import OrderDialog from "../orderDialog/OrderDialog.vue";
import DialogWrapper from "../orderDialog/DialogWrapper.vue";

export default {
  name: "CallCenterComponent",
  mixins: [user, notifications, loader],
  data: () => ({
    treeSideBar: true,
    boardsItem: {},
    countAll: 0,
    boardSetting: {
      visible: false,
      list: []
    },
    states: [],
    activeStatus: "00000000-0000-0000-0000-000000000000",
    itemDialog: {
      visible: false,
      type: "add",
      item: {},
      user_states: []
    },
    createDialog: {
      visible: false,
      type: "add",
      item: {},
      user_states: []
    }
  }),
  async mounted() {
    EventBus.$on("open-content", item => {
      this.resetItemDialog();
      setTimeout(() => {
       
        this.itemDialog.visible = true;
        this.itemDialog.item = item;
        this.itemDialog.user_states = this.states
      });
    });
    EventBus.$on("crm-items-reset", () => {
      this.resetItemDialog();
    });

    this.updateBoard();

  },

  methods: {
    setAll(count) {
      this.countAll = count;
      this.states[0].call_required_amount = this.countAll;
    },
    ...mapActions("crm", {
      setUuidBoard: actionTypes.SET_UUID_BOARD,
      setUuidTab: actionTypes.SET_UUID_TAB
    }),
    resetItemDialog() {
      this.itemDialog = {
        visible: false,
        item: {},
        user_states: [],
        type: "edit"
      };
    },
    onAddOrder() {
      this.createDialog = {
        visible: true,
        item: {},
        user_states: [],
        type: "add"
      };
    },
    changeBoard() {
      // orderDialog('changeBoard');
      this.resetItemDialog();
      this.setUuidBoard(this.boardsItem.uuid || "");
      this.getStateList();
      this.activeTab = 0;
      this.uuid_filter = null;
    },
    async updateBoard() {
      try {
        this.setLoading(true);
        await crmService.getCrmBoardsList().then(response => {
          this.boardSetting.list = response;
          // eslint-disable-next-line prefer-destructuring
          if (response.length) {
            if (this.uuid_board.length) {
              // // orderDialog('uuid_board', this.uuid_board);
              this.boardsItem = {
                uuid: this.uuid_board
              };
            } else {
              this.setUuidBoard(response[0].uuid);
              // eslint-disable-next-line prefer-destructuring
              this.boardsItem = response[0];
            }
            this.getStateList().then(() => {
              if (this.uuid_tab.length) {
                this.states.forEach((item, index) => {
                  if (this.uuid_filter === item.uuid) {
                    this.activeTab = index;
                  }
                });
              }
            });
          }
          this.setLoading(false);
        });
      } catch (e) {
        this.setErrorNotification(e);
        this.setLoading(false);
      }
    },
    async getStateList() {
      try {
        this.setLoading(true);
        const params = {};
        params.show_call_required_amount = true;
        const stateList = await crmService.getCrmUserStateList(this.boardsItem.uuid, params);

        const startTab = {
          name: this.$t("btn.all"),
          uuid_user: true,
          uuid: "00000000-0000-0000-0000-000000000000",
          position: 0,
          noEdit: true,
          call_required_amount: this.countAll,
          color: "#FFF"
        };
        this.states = [startTab, ...stateList];
       
        this.setLoading(false);
      } catch (e) {
        this.setErrorNotification(e);
        this.setLoading(false);
      }
    }
  },
  computed: {
    ...mapGetters("crm", {
      uuid_board: getterTypes.GET_UUID_BOARD,
      uuid_tab: getterTypes.GET_UUID_TAB
    })
  },
  components: {
    OrderItemsList,
    ContentDialog,
    OrderDialog,
    DialogWrapper
  }
};
</script>

<style lang="scss" scoped>
.call-center__aside {
  width: 100%;
  display: block;
  height: 80vh;
  overflow-y: scroll !important;
}
.call-center {
  &__wrapper {
    height: inherit;
    height: 88.5vh;
    // overflow: scroll;
    // overflow: hidden;
    padding: 10px 0;
    pointer-events: auto;
    position: relative;
    display: block;
  }
  &__content {
    height: 88vh;
  }

  &__left-side {
    overflow: scroll;
    height: 100%;
    &::-webkit-scrollbar {
      background-color: unset !important;
    }
  }
}
.user-state {
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #eeeeee;
  }
}
</style>

<template>
  <div class="item__wrapper">
    <v-row no-gutters justify="space-between" align="center" class="pa-1" @click="onClickItem">
      <v-col cols="5">
        <v-col class="text-truncate pb-0">
          <span class="font-weight-bold">{{
            `${item.delivery_address_details.name || "--"} ${item.delivery_address_details
              .surname || "--"}`
          }}</span>
        </v-col>
        <v-col class="pt-0">
          <div :style="`background-color: ${getState.color};`" class="state-bg">
            <span>
              {{ getState.name || "--" }}
            </span>
          </div>
        </v-col>
      </v-col>
      <v-col cols="5" class="text-truncate">
        <span>{{ item.delivery_address_details.phone || "--" }}</span>
      </v-col>
      <v-col cols="auto">
        <VIcon>mdi-phone</VIcon>
      </v-col>
    </v-row>
    <v-divider />
  </div>
</template>

<script>
import EventBus from "@/events/EventBus";

export default {
  name: "orderItem",
  props: {
    item: {
      require: true
    },
    states: Array
  },
  mounted() {},
  methods: {
    onClickItem() {
      EventBus.$emit("open-content", this.item);
    }
  },
  computed: {
    getState() {
      const state = this.states.filter(state => state.uuid === this.item.uuid_user_state);
      return state[0] || "--";
    }
  }
};
</script>

<style lang="scss" scoped>
.item {
  &__wrapper {
    cursor: pointer;
    &:hover {
      background: #e4e4e448;
    }
  }
}
.state-bg {
  border-radius: 5px;
  text-align: center;
  border: thin solid #e3e3e3a5;
  span {
    font-weight: 400;
  }
}
</style>

<template>
    <CallCenterComponent />
  </template>
  
  <script>
  import CallCenterComponent from '../components/call-center/CallCenterComponent.vue';
  
  export default {
    name: 'CallCenter',
    components: {
        CallCenterComponent,
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  
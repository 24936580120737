<template>
  <div>
    <div v-if="items.length">
    
      <order-item :states="states" :item="item" v-for="item in items" :key="item.uuid" />
      
    </div>
    <div v-else class="cabinet-del-state-list pa-0" >
      <div class="d-flex align-center justify-content-center" style="height:inherit">
        <span class="font-weight-medium text-h6">{{ $t("table.noData") }}</span>
      </div>
    </div>

  </div>
</template>

<script>
import orderItem from "./orderItem.vue";
import notifications from "@/mixins/notifications";
import loader from "@/mixins/loader";
import crmService from "@/services/request/crm/crmService";
import EventBus from "@//events/EventBus";

export default {
  components: { orderItem },
  props: {
    uuid_board: {
      require: true
    },
    states: Array,
    activeStatus: {
      require: true
    }
  },
  data: () => ({
    items: [],
    filterProps: {
      filter_ready_to_deliver: false
    },
    loadMoreDisabled: false
  }),
  mixins: [loader, notifications],
  mounted(){
    EventBus.$on("crm-items-reset", () => {
     this.updateItems()
    });
  },
  watch: {
    uuid_board: {
      deep: true,
      handler(e) {
        this.updateItems();
      }
    },
    activeStatus: {
      deep: true,
      handler({ uuid }) {
        const params = {};
        if (uuid !== "00000000-0000-0000-0000-000000000000") {
          params.uuid_user_state = uuid;
        }
        
        EventBus.$emit('crm-items-reset')
        this.updateItems(params);
      }
    }
  },
  methods: {
    async updateItems(param) {
      try {
        this.setLoading(true);
        const params = { ...param };
        Object.keys(this.filterProps).forEach(key => {
          if (this.filterProps[key]) {
            params[key] = this.filterProps[key];
          }
        });

        const orderList = await crmService.getCrmOrderList({
          uuid_board: this.uuid_board,
          is_call_required: 1,
          ...params
        });
        this.items = orderList;
        let count = 0
        orderList.forEach((item) => {
          if (item.uuid_user_state == '00000000-0000-0000-0000-000000000000') {
            count += 1
          }
        })
        this.$emit('set_all', count)

        // this.loadMoreDisabled = this.items.length < this.perPage;
        this.loadingItems = false;
      } catch (e) {
        this.loadingItems = false;
        this.setErrorNotification(e);
      } finally {
        this.setLoading(false);
      }
    }
  }
};

</script>

